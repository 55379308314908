footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: right;
  z-index: 999;
}

footer .copyright {
  display: inline-block;
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top: 2px solid #f5f5f4;
  border-left: 2px solid #f5f5f4;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
}