.RedocPage-header {
    position: fixed;
    top: 0;
    padding: 10px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #f5f5f4;
    z-index: 999;
  }
  
  .RedocPage-header img {
    height: 30px;
    width: auto;
  }
  
  .RedocPage-header .select__api {
    max-width: 50vw;
    width: 500px;
    padding-right: 30px;
  }
  
  section.container__redoc {
    padding-top: 60px;
  }